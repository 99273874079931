import React from "react"
import Layout from "../../../Components/pageComponents/contentContainer"
import scrollTo from 'gatsby-plugin-smoothscroll'
// import BackgroundImage from 'gatsby-background-image'
import SquareCard from "../../../Components/pageComponents/squareCard"
import { Container, Col, Row } from "reactstrap"
import Img from "gatsby-image"

export const query = graphql`
    query {
        banner: file(relativePath: { eq: "products/physical-infastructure/Physical Infrastructure.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        
        ladder: file(relativePath: { eq: "products/physical-infastructure/Cable Trays and Ladders Icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        cool: file(relativePath: { eq: "products/physical-infastructure/Cooling Icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        ups: file(relativePath: { eq: "products/physical-infastructure/Main Power and UPS Icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        cabinets: file(relativePath: { eq: "products/physical-infastructure/Racks and Cabinets Icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        floor: file(relativePath: { eq: "products/physical-infastructure/Raised Flooring Icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        cabling: file(relativePath: { eq: "products/physical-infastructure/Structured Cabling Icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        compare: file(relativePath: { eq: "products/physical-infastructure/Copper VS Fiber.png" }) {
            childImageSharp {
                fluid(maxWidth: 1600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

    }
`

export default ({ data }) => {
    return (
        <Layout title="Physical Infrastructure" image={data.banner.childImageSharp.fluid} >
            <Container className="d-flex flex-wrap justify-content-center py-3">
                <SquareCard img={data.ladder.childImageSharp.fluid} title="Cable Tray and Ladders"></SquareCard>
                <SquareCard img={data.cool.childImageSharp.fluid} title="Cooling"></SquareCard>
                <SquareCard img={data.ups.childImageSharp.fluid} title="Main Power and UPS"></SquareCard>
                <SquareCard img={data.cabinets.childImageSharp.fluid} title="Racks and Cabinets"></SquareCard>
                <SquareCard img={data.floor.childImageSharp.fluid} title="Raised Flooring"></SquareCard>
                <SquareCard img={data.cabling.childImageSharp.fluid} title="Structured Cabling"> <button onClick={() => scrollTo('#structured')} className="btn button1">Learn More</button></SquareCard>
            </Container>

            <Container fluid={true}>
                <Row id="structured">
                    <Col md={6} className="pt-5 bg-gold">
                        {/* Image here */}
                        <h3 className="px-lg-5">Complete cabling system that provides a comprehensive network infrastructure that provides connectivity for the user’s voice, video, and data. Consist of the following subsystems:</h3>
                        <h4 className="pl-lg-5 mt-3">
                            <ol>
                                <li>Entrance Facilities</li>
                                <li>Equipment Rooms</li>
                                <li>Backbone Cabling</li>
                                <li>Horizontal Cabling</li>
                                <li>Telecommunication rooms</li>
                                <li>Work Area</li>
                            </ol>
                        </h4>
                    </Col>

                    <Col md={6} className="pt-5 bg-gray">
                        <h3 className="px-lg-5 ">
                            Our installation complies with the Network Cabling Standards and backed by
                            a 25 Years System Warranty.
                        </h3>
                        <h4 className="pl-lg-5 pr-3 mt-3">
                            <ul>
                                <li>Copper Cable variety includes CAT 5, CAT 5e, and CAT 6 Networks.</li>
                                <li>Fiber Optic Cables transmit data at a speed closely comparable to light.</li>
                                <li>Multiple brands are available to suit your preferences, requirements, and comfort.</li>
                                <li>Certified Engineers to Assess, Plan, Implement, and Maintain your Connectivity.</li>
                            </ul>
                        </h4>
                    </Col>
                </Row>
            </Container>

            <Container
                fluid
                className="pt-5 pb-5"
                style={{ backgroundColor: `var(--FSC-WHITE)` }}

            >
                <h1 className="mx-auto mb-4 heading1 p-3 text-center">
                    <span>Copper VS Fiber</span>
                </h1>
                <div style={{
                    width: `100%`,
                    minWidth: `280px`,
                    maxWidth: `1000px`,
                    display: `block`,
                    marginLeft: `auto`,
                    marginRight: `auto`,
                }}>
                    <Img fluid={data.compare.childImageSharp.fluid} />
                </div>

            </Container>



            {/* Image here */}

        </Layout>
    )
}